import React from "react";
import './VideoList.css'

class VideoBlock extends React.Component {
    render() {
        return (
            <div className={"video_block"}>
                <div className={'video_img'}>
                    <img src={this.props.imageUrl} alt={''}
                         style={{width: '100%', height: 'auto'}}/>
                </div>
                <div className={"video_text"}>
                    {this.props.Text}
                </div>
            </div>
        );
    }
}

class VideoList extends React.Component {
    render() {
        return (
            <div>
                <h2 className={"video_title"}>{this.props.Title}</h2>
                <div className={'video_list'}>
                    {this.props.videos.map(text => {
                        return <div key={text} onClick={() => this.props.onClick(this.props.Title, text)}>
                            <VideoBlock key={text}
                                        Text={text}
                                        imageUrl={this.ImgUrl + this.props.Title + '/' + text}
                            />
                        </div>
                    })}
                </div>
            </div>
        );
    }
}

export default VideoList

