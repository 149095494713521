import React from "react";

class Play extends React.Component {
    render() {
        return (
            <div>
                <Player VideoUrl={this.VideoUrl + this.props.Series + '/' + this.props.Name}/>
            </div>
        );
    }
}

class Player extends React.Component {
    render() {
        return (
            <div>
                <video src={this.props.VideoUrl} controls="controls">您的浏览器不支持 video 标签</video>
            </div>
        );
    }
}

export default Play
