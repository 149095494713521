import './App.css';
import VideoList from "./VideoList";
import React from "react";
import Play from "./Play";

React.Component.prototype.ImgUrl = 'https://v.one-api.tech/img/'
React.Component.prototype.VideoUrl = 'https://v.one-api.tech/video/'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            watch: false,
            selectSeries: '',
            selectVideo: '',
        }
    }

    render() {
        return (
            <div>
                {
                    !this.state.watch &&
                    <Show onClick={(series, video) => this.setState({
                        watch: true,
                        selectSeries: series,
                        selectVideo: video,
                    })}/>
                }
                {
                    this.state.watch &&
                    <Watch
                        Series={this.state.selectSeries}
                        Name={this.state.selectVideo}
                        onClick={() => this.setState(
                            {
                                watch: false,
                            })
                        }
                    />
                }
            </div>
        );
    }


}

class Show extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            video: {},

        }
    }

    render() {
        return (
            <div>
                <div style={{margin: '20px'}}>
                    {
                        this.state.series.map(title => {
                            return <VideoList Title={title}
                                              key={title}
                                              videos={this.state.video[title]}
                                              onClick={this.props.onClick}

                            />
                        })
                    }
                </div>
            </div>
        );
    }

    componentDidMount() {
        fetch('/video_list').then(resp => {
            return resp.json()
        }).then(json => {
            let series = []
            let video = {}
            for (let item of json['series']) {
                let name = item['name']
                let videos = item['videos']
                series.push(name)
                video[name] = videos
            }
            this.setState({
                series: series,
                video: video
            })
        })
    }
}


class Watch extends React.Component {
    render() {
        return (
            <div>
                <button onClick={this.props.onClick}> Back</button>
                <div>{this.props.Series}</div>
                <div>{this.props.Name}</div>
                <Play Series={this.props.Series} Name={this.props.Name}/>
            </div>
        );
    }
}

export default App;
